<template>
  <div id="top">
    <a-steps :current="current">
      <a-step v-for="item in steps" :key="item.title" :title="item" />
    </a-steps>
    <div class="steps-content">
      <a-row
        v-if="current === contents.info"
        type="flex"
        justify="space-around"
        align="middle"
      >
        <a-form-model
          style="margin-left: 3%; margin-right: 3%"
          ref="addressForm"
          :rules="addressRules"
          :model="form"
        >
          <a-col
            data-aos="zoom-out"
            data-aos-easing="ease-in-out"
            data-aos-delay="100"
            data-aos-duration="2000"
            :span="24"
          >
            <a-form-model-item
              style="margin-right: 10px"
              prop="OrcamentoCidadeId"
            >
              <h2 class="big-label">
                Selecione a cidade onde sua reforma se localiza:
              </h2>
              <a-select
                style="width: 60%"
                v-model="form.OrcamentoCidadeId"
                placeholder="Selecione a cidade"
              >
                <a-select-option
                  v-for="city in cities"
                  :key="city.cidadeID"
                  :value="city.cidadeID"
                >
                  {{ city.cidadeNome }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col
            data-aos="zoom-out"
            data-aos-easing="ease-in-out"
            data-aos-delay="100"
            data-aos-duration="1000"
            :span="24"
          >
            <a-row type="flex" align="middle" justify="space-around">
              <a-col :sm="24" :md="12">
                <a-form-model-item
                  type="number"
                  prop="OrcamentoArea"
                  style="margin-right: 5%"
                >
                  <h2 class="m-label">
                    Qual a área total aproximada do seu apartamento ou casa?
                  </h2>
                  <a-input
                    placeholder="135"
                    style="width: 50%"
                    addon-after="m²"
                    v-model="form.OrcamentoArea"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-col>
        </a-form-model>
      </a-row>

      <div v-if="current === contents.ambientes">
        <RoomsComponent
          :servicosPorAmbiente="servicosPorAmbiente"
          :area="form.OrcamentoArea"
          :cidadeID="form.OrcamentoCidadeId"
        />
      </div>

      <div v-if="current === contents.servicos">
        <ServicesComponent :servicosPorAmbiente="servicosPorAmbiente" />
      </div>

      <div v-if="current === contents.personal">
        <h2 class="big-label">
          Falta pouco! Só precisamos do seu contato e informações básicas,
          <br />
          assim podemos lhe atender melhor e guardar seu orçamento.
        </h2>
        <a-row type="flex" align="middle" justify="space-around">
          <a-form-model
            style="margin-left: 3%; margin-right: 3%"
            ref="contactForm"
            :rules="contactRules"
            :model="form"
          >
            <a-col :sm="24" :md="12">
              <a-form-model-item prop="OrcamentoNome">
                <h2 class="m-label">Qual seu nome completo?</h2>
                <a-input
                  style="width: 90%"
                  placeholder="Nome e Sobrenome"
                  v-model="form.OrcamentoNome"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="OrcamentoEmail">
                <h2 class="m-label">Qual seu E-mail?</h2>
                <a-input
                  type="email"
                  style="width: 95%"
                  placeholder="email@dominio.com.br"
                  v-model="form.OrcamentoEmail"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="24">
              <a-form-model-item prop="OrcamentoProjetoInterior">
                <h2 class="m-label">
                  Gostaria de Orçar o Projeto de Interiores?
                </h2>
                <a-radio-group
                  :default-value="1"
                  button-style="solid"
                  v-model="form.OrcamentoProjetoInterior"
                >
                  <a-radio-button default-checked value="1">
                    Sim, Preciso do Projeto de Interiores
                  </a-radio-button>
                  <a-radio-button value="0">
                    Não, Já tenho o Projeto de Interiores
                  </a-radio-button>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item style="margin-left: 5%; margin-right: 2%">
                <h2 class="m-label">
                  Faltou alguma coisa que queira nos contar?
                </h2>
                <a-input
                  placeholder="Ex: Quero integrar a cozinha com a sala, estilo cozinha gourmet"
                  v-model="form.OrcamentoObs"
                  type="textarea"
                />
              </a-form-model-item>
            </a-col>
          </a-form-model>
        </a-row>
      </div>
    </div>

    <div class="steps-action" align="middle">
      <a-button v-if="current > 0" style="margin-right: 8px" @click="prev">
        Etapa Anterior
      </a-button>

      <a-button v-if="current < steps.length - 1" type="primary" @click="next">
        Próxima Etapa
      </a-button>

      <a-button
        v-if="current == steps.length - 1"
        type="primary"
        :loading="loading"
        @click="cabecalhoOrcamento"        
        >
        Terminar
      </a-button>
    </div>

    <a-row type="flex" align="middle" justify="space-around">
      <h2 class="m-label" v-if="current === contents.info">
        <a
          :href="`https://api.whatsapp.com/send/?phone=5547992178253&text=Olá!%0DDesejo saber mais informações sobre reformas comerciais.`"
          target="_blank"
        >
          Para reformas comerciais ou corporativas entre em contato com a gente
          através do WhatsApp ou e-mail
        </a>
      </h2>
    </a-row>

    <a-modal
      :afterClose="() => ((current = contents.info), this.$router.go())"
      :footer="null"
      @cancel="() => (visible = false)"
      :visible="visible"
      centered
    >
      <a-row align="middle" justify="center">
        <a-col align="center" :span="24">
          <h2 class="big-label" style="font-size: 1.6em">
            Seu orçamento foi realizado com sucesso!
            <br />
            O valor total aproximado é entre
          </h2>
          <h2 class="big-label" style="font-size: 2em">
            R$ {{ this.orcamento.orcamentoValorTotalMinimo | real }}
            <br />
            e
            <br />
            R$ {{ this.orcamento.orcamentoValorTotalMaximo | real }}
          </h2>
        </a-col>

        <a-col align="center" :span="24">
          <h2 class="m-label" style="color: black">
            Para mais detalhes uma cópia do orçamento foi enviada ao seu e-mail
            cadastrado.
          </h2>
          <a-button
            type="primary"
            size="large"
            style="margin-top: 10px; margin-bottom: 10px"
          >
            <a
              :href="`https://api.whatsapp.com/send/?phone=5547992178253&text=Olá!%0DFiz o orçamento ${this.orcamento.orcamentoId} e desejo saber mais informação sobre a reforma.`"
              target="_blank"
            >
              Converse agora com a nossa equipe!
            </a>
          </a-button>
        </a-col>
        <a-col align="center" :span="24">
          <h2 class="m-label" style="color: black">
            Ou responda o e-mail que acabamos de enviar, e conte-nos como foi
            sua experiencia.
          </h2>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "WizardComponent",
  data() {
    return {
      visible: false,
      current: 0,
      loading: false,
      steps: [
        "Informações Gerais",
        "Ambientes a Reformar",
        "Serviços Desejados",
        "Seus Dados",
      ],
      contents: {
        info: 0,
        ambientes: 1,
        servicos: 2,
        personal: 3,
      },
      servicosPorAmbiente: [],
      form: {
        OrcamentoCidadeId: 1,
        OrcamentoArea: null,
        OrcamentoNome: null,
        OrcamentoEmail: null,
        OrcamentoProjetoInterior: 0,
        OrcamentoObs: null,
        OrcamentoValorTotalMinimo: 0,
        OrcamentoValorTotalMaximo: 0,
        orcamentoAmbientes: [],
      },
      addressRules: {
        OrcamentoCidadeId: [
          {
            required: true,
            message: "Por favor selecione sua cidade",
            trigger: "blur",
          },
        ],
        OrcamentoArea: [
          {
            required: true,
            message: "Por favor preencha a área do seu ímovel",
            trigger: "blur",
          },
          {
            message: "Precisa ser um número",
            trigger: "change",
            pattern: /\d/g /*/\[0-9]/g*/,
          },
        ],
      },
      contactRules: {
        OrcamentoNome: [
          {
            required: true,
            message: "Por favor preencha seu nome",
            trigger: "blur",
          },
        ],
        OrcamentoEmail: [
          {
            required: true,
            message:
              "Por favor preencha o e-mail através dele você irá receber esse orçamento",
            trigger: "blur",
          },
          {
            message: "E-mail inválido",
            trigger: "change",
            pattern:
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          },
        ],
        OrcamentoProjetoInterior: [
          {
            required: true,
            message:
              "Por favor selecione se desejas Receber Orçamento de Projeto de Interiores",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      orcamento: (state) => state.orcamento.novoOrcamento,
      cities: (state) => state.city.cities,
    }),
  },
  methods: {
    next() {
      if (this.current === this.contents.info) {
        this.$refs.addressForm.validate((isValid) => {
          if (isValid) {
            let { OrcamentoArea } = this.form;
            if (OrcamentoArea) {
              window.scrollTo(0, 0);
              this.current++;
            } else {
              if (!OrcamentoEndereco || !OrcamentoArea) {
                this.$message.warn(
                  "Para prosseguir é necessário preencher a área do apartamento!"
                );
              }
            }
          } else {
            this.$notification.error({
              message: "O formulário está inválido.",
              description:
                "Por favor confira todas as informações e tente novamente.",
            });
          }
        });
      } else if (this.current === this.contents.ambientes) {
        if (this.servicosPorAmbiente.length <= 0) {
          this.$message.warn(
            "Para prosseguir é necessário adicionar pelo menos um ambiente!"
          );
        } else {
          window.scrollTo(0, 0);
          this.current++;
        }
      } else if (this.current === this.contents.servicos) {
        // console.log("servicosPorAmbiente: " + JSON.stringify(this.servicosPorAmbiente));
        let checked = 0;
        var ambientTitles = [];
        this.servicosPorAmbiente.forEach((ambient) => {
          ambientTitles.push(ambient.ambienteNome);
          for (var i = 0; i < ambient.NovosServicos.length; i++) {
            if (ambient.NovosServicos[i].ServicoSelecionado) {
              const ambientValid = ambientTitles.findIndex(
                (value) => ambient.key === value
              );
              ambientTitles.splice(ambientValid, 1);
              checked++;
              break;
            }
          }
        });

        if (checked >= this.servicosPorAmbiente.length) {
          window.scrollTo(0, 0);
          this.current++;
        } else {
          this.$message.warn(
            `Para prosseguir é necessário selecionar pelo menos um serviço para ${ambientTitles}!`
          );
        }
      } else {
        this.current++;
      }
    },
    prev() {
      this.current--;
    },
    getErrors(data) {
      // console.log("getErrors");
      const messages = [];
      if (data.OrcamentoEmail) {
        data.OrcamentoEmail.forEach((e) => {
          messages.push(`${e} `);
        });
      }

      if (data.OrcamentoNome) {
        data.OrcamentoNome.forEach((e) => {
          messages.push(`${e} `);
        });
      }

      if (data.OrcamentoArea) {
        data.OrcamentoArea.forEach((e) => {
          messages.push(`${e} `);
        });
      }

      return messages;
    },
    getWantedServices() {
      //console.log("servicosPorAmbiente:" + JSON.stringify(this.servicosPorAmbiente));
      this.servicosPorAmbiente.forEach((ambient) => {
        if (ambient.ambienteQtd > 0) {
          this.form.OrcamentoValorTotalMinimo += ambient.AmbienteVlrMinTotal;
          this.form.OrcamentoValorTotalMaximo += ambient.AmbienteVlrMaxTotal;
          this.form.orcamentoAmbientes.push({
            OrcamentoAmbienteId: ambient.ambienteId,
            OrcamentoAmbienteItem: ambient.ambienteQtd,
            OrcamentoAmbienteValorMinimo: ambient.AmbienteVlrMinTotal,
            OrcamentoAmbienteValorMaximo: ambient.AmbienteVlrMaxTotal,
            OrcamentoAmbienteArea: ambient.ambienteArea,
            OrcamentoAmbientePerimetro: ambient.ambientePerimetro,
            OrcamentoServicos: this.fetchServices(
              ambient.NovosServicos,
              ambient.ambienteId,
              ambient.ambienteQtd
            ),
          });
        }
      });
      // this.form.OrcamentoValorTotal = valortotal;
      //console.log("OrcamentoAmbientes: " + JSON.stringify(this.form.orcamentoAmbientes));
    },

    fetchServices(serviceLista, ambienteId, ambienteQtd) {
      let newList = [];

      serviceLista.forEach((servico) => {
        if (servico.ServicoSelecionado) {
          newList.push({
            OrcamentoAmbienteId: ambienteId,
            OrcamentoAmbienteItem: ambienteQtd,
            OrcamentoServicoId: servico.vwAmbienteServicoId,
            OrcamentoServicoValorMinimo: servico.servicoValorMinimo,
            OrcamentoServicoValorMaximo: servico.servicoValorMaximo,
          });
        }
      });
      return newList;
    },

    cabecalhoOrcamento() {
      
      console.log("Orcamento: " + JSON.stringify(this.form));

      this.$refs.contactForm.validate((isValid) => {
        if (isValid) {
          this.loading = true;
          this.getWantedServices();
          this.$store.dispatch("orcamento/PostOrcamento", this.form).then(
            (data) => {
              this.visible = true;
              this.form = {
                OrcamentoCidadeId: 1,
                OrcamentoArea: 0,
                OrcamentoNome: null,
                OrcamentoEmail: null,
                OrcamentoProjetoInterior: 0,
                OrcamentoObs: null,
                OrcamentoValorTotalMinimo: 0,
                OrcamentoValorTotalMaximo: 0,
                orcamentoAmbientes: [],
                OrcamentoServicos: [],
                servicosPorAmbiente: [],
              };
              this.loading = false;
              //console.log("após limpar");
            },
            (error) => {
              const messages = this.getErrors(error.response.data);
              this.$notification.error({
                message: "Houve um erro durante a solicitação do orçamento.",
                description: messages,
              });
            }
          );
        } else {
          this.$notification.error({
            message: "O formulário está inválido.",
            description:
              "Por favor confira todas as informações e tente novamente.",
          });
        }
      });
    },
  },
  mounted() {
    this.$store.dispatch("city/GetCidade");
    // console.log("mounted");
  },
};
</script>

<style scoped>
.ant-form-item-label > label::after {
  margin: 10px;
  content: none !important;
  /* background-color: #b83390a9; */
}

.steps-content {
  margin-top: 1px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  text-align: center;
  /* padding-top: 80px; */
  /* background-color: #b83333; */
}

.steps-action {
  margin-top: 24px;
  /* background-color: #8e33b8a9; */
}

.big-label {
  color: #1f8800;
  font-size: 1.8em;
  margin: 20px;
  /* background-color: #3362b8a9; */
}

.m-label {
  color: #1f8800;
  font-size: 1.4em;
  margin: 20px;
  /* background-color: #33b86f; */
}

.m-aviso {
  color: #1f8800;
  font-size: 1em;
  margin: 20px;
  /* background-color: #33b86f; */
}

/* .step-info {
  color: #171a16;
  font-size: 1.2em;
  margin: 2px;
  margin-bottom: 20px;
  background-color: #b86f33;
} */
</style>
