import Vue from 'vue'
import Router from 'vue-router'

import CalculatorPage from '@/pages/CalculatorPage'

Vue.use(Router)

export const router = new Router({
    routes: [{
        path: '/',
        name: 'Calculator',
        component: CalculatorPage
    }]
})