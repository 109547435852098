import axios from 'axios'

export const api = () => {
    return axios.create({
        /*Desenvolvimento*/
        // baseURL: 'http://localhost:5000/api/',

        /*Produção*/
        baseURL: 'https://www.api.jafix.com.br.hulk.hostazul.com.br/api/',

        headers: {
            'Content-Type': 'application/json'
        }
    })
}