<template>
 <a-layout class="layout">
    <!-- <a-layout-header class="home-header">
      <img src="~@/assets/jafix_logo.png" class="nav-logo" />
    </a-layout-header> -->
    <a-layout-content>
      <!-- <div id="calculator" class="bg-border"> -->
        <div class="bg" >
          <WizardComponent />
        </div>
     <!-- </div> -->
    </a-layout-content>
    <!-- <a-layout-footer style="text-align: center">
      Todos os Direitos Reservados a Jafix ©{{ new Date().getFullYear() }}
    </a-layout-footer> -->
  </a-layout>
</template>

<script>
export default {

}
</script>

<style scoped>
/* xl */
@media (min-width: 1200px) {
  .bg-border {
    padding: 50px;
    background: #313131;
  }

  .bg {
    padding: 24px;
    background: #fff;
  }
}

/* lg */
@media (max-width: 1199px) and (min-width: 992px) {
  .bg-border {
    padding: 50px;
    background: #313131;
  }

  .bg {
    padding: 24px;
    background: #fff;
  }
}

/* md */
@media (max-width: 991px) and (min-width: 768px){
	.bg-border {
    padding: 30px;
    background: #313131;
  }

  .bg {
    padding: 20px;
    background: #fff;
  }
}

/* sm */
@media (max-width: 767px) and (min-width: 576px){
  .bg-border {
    padding: 10px;
    background: #313131;
  }

  .bg {
    padding: 10px;
    background: #fff;
  }
}

/* xs */
@media (max-width: 575px){
	.bg-border {
    padding: 5px;
    background: #313131;
  }

  .bg {
    padding: 10px;
    background: #fff;
  }
}

.home-header {
  background: white;
  height: 130px !important;
}

.nav-logo {
  height: 140px;
  width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
