<template>
  <a-list
    style="margin-top: 50px"
    :grid="{ gutter: 48, sm: 1, md: 2, lg: 3 }"
    :data-source="ambientes"
  >
    <a-list-item
      data-aos="flip-left"
      data-aos-easing="ease-in-out"
      data-aos-delay="100"
      data-aos-duration="2000"
      slot="renderItem"
      slot-scope="item"
    >
      <a-card class="cardAmbientSize">
        <img
          slot="cover"
          alt="ambient"
          class="room-icon"
          :src= item.ambienteIcone.trim()
          
        />

        <a-card-meta :title="item.ambienteNome" />

        <template
          style="background: #b6dcab"
          slot="actions"
          class="ant-card-actions"
        >
          <a-icon
            key="minus"
            @click="removeRoom(item.vwAmbienteId)"
            type="minus"
          />
          <p
            key="amount"
            style="margin: 0; font-size: 1.5em; color: #1f8800; cursor: default"
          >
            {{ item.ambienteQtd }}
          </p>
          <a-icon key="plus" @click="addRoom(item)" type="plus" />
        </template>
      </a-card>
    </a-list-item>
  </a-list>
</template>


<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "RoomsComponent",
  props: ["servicosPorAmbiente", "area", "cidadeID"],
  data() {
    // console.log("data()");
    return {};
  },
  computed: {
    ...mapState({
      ambientes: (state) => state.ambientes.ambientes,
    }),
  },
  methods: {
    addRoom(ambienteSelecionado) {
      this.ambientes.length > 0 &&
        this.ambientes.forEach((ambiente) => {
          if (ambiente.vwAmbienteId === ambienteSelecionado.vwAmbienteId) {
            ambiente.ambienteQtd++;
            this.servicosPorAmbiente.push({
              ...ambienteSelecionado,
              AmbienteVlrMinTotal: Number(0),
              AmbienteVlrMaxTotal: Number(0),
              AmbienteKey: `${ambiente.vwAmbienteId} - ${ambiente.ambienteQtd}`,
              NovosServicos: this.fetchServices(
                ambiente.ambienteServicos,
                `${ambiente.vwAmbienteId} - ${ambiente.ambienteQtd}`
              ),
            });
          }
        });
    },
    fetchServices(serviceLista, ambiente) {
      let newList = [];

      serviceLista.forEach((servico) => {
        newList.push({ ...servico, AmbienteKey: ambiente });
      });

      return newList;
    },
    removeRoom(id) {
      this.ambientes.length > 0 &&
        this.ambientes.forEach((ambiente) => {
          // console.log('passou 1');
          if (ambiente.vwAmbienteId === id && ambiente.ambienteQtd > 0) {
            // console.log('passou 2');
            let oldRoom = this.servicosPorAmbiente.findIndex((value) => {
              // console.log('passou 3');
              let roomKeyIndex = value.AmbienteKey.search("-");
              // console.log('passou 4');
              let roomIndex = parseInt(
                value.AmbienteKey.slice(roomKeyIndex + 1)
              );
              // console.log('passou 5');
              return (
                id === value.vwAmbienteId && ambiente.ambienteQtd === roomIndex
              );
            });
            // console.log('passou 7');
            this.servicosPorAmbiente.splice(oldRoom, 1);
            // console.log('passou 8');
            ambiente.ambienteQtd--;
          }
        });
      // console.log(JSON.stringify(this.servicosPorAmbiente));
    },
  },
  mounted() {
    
    if (this.servicosPorAmbiente.length > 0) {
      //console.log("Tem servicosPorAmbiente");
    } else {
      console.log("mounted Area: " + this.area + " - cidadeID: " + this.cidadeID);
      this.$store.dispatch(
        "ambientes/GetAmbiente",
        this.area + "," + this.cidadeID
      );
    }
  },
};
</script>

<style scoped>
@media (min-width: 1720px) {
  .room-icon {
    width: 250px;
    margin: auto;
    /* margin-left: 25%; */
  }
}

@media (max-width: 1719px) and (min-width: 1445px) {
  .room-icon {
    width: 250px;
    margin: auto;
    margin-top: 10%;
  }
}

@media (max-width: 1445px) and (min-width: 1220px) {
  .room-icon {
    width: 250px;
    margin: auto;
    margin-top: 10%;
  }
}

@media (max-width: 1219px) and (min-width: 1099px) {
  .room-icon {
    width: 250px;
    margin: auto;
    margin-top: 10%;
  }
}

@media (max-width: 1098px) and (min-width: 976px) {
  .room-icon {
    width: 150px;
    margin: auto;
    margin-top: 10%;
  }
}

@media (max-width: 975px) and (min-width: 872px) {
  .room-icon {
    width: 200px;
    margin: auto;
    margin-top: 10%;
  }
}

@media (max-width: 871px) and (min-width: 760px) {
  .room-icon {
    width: 250px;
    margin: auto;
    margin-top: 10%;
  }
}

@media (max-width: 759px) and (min-width: 546px) {
  .room-icon {
    width: 250px;
    margin: auto;
    margin-top: 10%;
  }
}

@media (max-width: 545px) and (min-width: 479px) {
  .room-icon {
    width: 250px;
    margin: auto;
    margin-top: 10%;
  }
}

@media (max-width: 478px) and (min-width: 434px) {
  .room-icon {
    width: 250px;
    margin: auto;
    margin-top: 10%;
  }
}

@media (max-width: 433px) and (min-width: 400px) {
  .room-icon {
    width: 190px;
    margin: auto;
    margin-top: 10%;
    /* background-color: #33b4b8; */
  }
}
@media (max-width: 399px) {
  .room-icon {
    width: 150px;
    margin: auto;
    margin-top: 10%;
    /* background-color: #33b4b8; */
  }
}

/* Cards */
@media (min-width: 992px) {
  .cardAmbientSize {
    width: 95% !important;
    height: 15% !important;
    margin-top: 3%;
    /* background-color: #b83385; */
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .cardAmbientSize {
    width: 95% !important;
    height: 15% !important;
    margin-top: 3%;
    /* background-color: #b83333; */
  }
}
@media (max-width: 767px) and (min-width: 576px) {
  .cardAmbientSize {
    width: 95% !important;
    height: 15% !important;
    margin-top: 3%;
    /* background-color: #5b33b8; */
  }
}
@media (max-width: 575px) {
  .cardAmbientSize {
    width: 95% !important;
    height: 10% !important;
    margin-top: 3%;
    /* background-color: #33b4b8; */
  }
}
</style>
