import { api } from '@/services/api'

const API = api()
const url = 'orcamento/'

export default {

    PostOrcamento(data) {
        //console.log('orcamentoService data: ' + JSON.stringify(data));
        console.log('PostOrcamento')
        return API.post(url, data)
    }
}