import cityService from '@/services/cityService'

const initialState = {
    cities: []
}

export default {
    namespaced: true,
    state: initialState,
    actions: {
        GetCidade: (store) => {
            //console.log('GetCidade');
            cityService.GetCidade()
                .then(({ data }) => {
                    store.commit('storeCities', data)
                })
                .catch(e => e)
        }
    },
    mutations: {
        storeCities: (state, cities) => {
            state.cities = cities
        }
    }
}