import ambienteService from '@/services/ambienteService'

const initialState = {
    ambientes: []
}

export default {

    namespaced: true,
    state: initialState,
    actions: {
        GetAmbiente: (store, parametro) => {
            //console.log('GetAmbiente');
            ambienteService.GetAmbientes(parametro)
                .then(({ data }) => {
                    const Quantity = []
                    data.forEach(ambientes => {
                        Quantity.push({
                            ...ambientes,
                            ambienteQtd: 0
                        })
                    })
                    store.commit('storeAmbientes', Quantity);
                })
                .catch(error => console.log(error));
        }
    },
    mutations: {
        storeAmbientes: (state, ambi) => {
            state.ambientes = ambi
        }
    }
}