import { api } from '@/services/api'

const API = api()
const url = 'cidades'

export default {
    GetCidade() {
        console.log('GetCidade')
        return API.get(url)
    }
}