import Vue from 'vue'
import App from './App.vue'
import Antd, { FormModel } from 'ant-design-vue';
import VueTheMask from 'vue-the-mask';
import { router } from './router';
import store from './store';
import AOS from 'aos';

import 'ant-design-vue/dist/antd.less';
import 'aos/dist/aos.css';

import WizardComponent from '@/components/WizardComponent';
import RoomsComponent from '@/components/steps/RoomsComponent';
import ServicesComponent from '@/components/steps/ServicesComponent';

Vue.config.productionTip = false

Vue.component('WizardComponent', WizardComponent);
Vue.component('RoomsComponent', RoomsComponent);
Vue.component('ServicesComponent', ServicesComponent);

Vue.use(Antd);
Vue.use(FormModel);
Vue.use(VueTheMask);
Vue.use(WizardComponent);
Vue.use(RoomsComponent);
Vue.use(ServicesComponent);

Vue.filter('real', (value) => {
    value = value && value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumfractiondigits: 2 });

    return value;
});

new Vue({
    created() {
        AOS.init()
    },
    render: h => h(App),
    store,
    router,
}).$mount('#app')