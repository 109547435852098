import { api } from '@/services/api'

const API = api()
const url = 'ambientes/'

export default {
    GetAmbientes(parametro) {
        console.log('GetAmbientes')
        return API.get(url + parametro)
    }
}