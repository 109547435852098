import Vue from 'vue'
import Vuex from 'vuex'
import orcamento from './modules/orcamento'
import city from './modules/city'
import ambientes from './modules/ambiente'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        orcamento,
        city,
        ambientes
    }
})