import orcamentoService from '@/services/orcamentoService'

const initialState = {
    orcamentos: [],
    novoOrcamento: {}
}

export default {
    namespaced: true,
    state: initialState,
    actions: {
        PostOrcamento: (store, form) => {
            // console.log('PostOrcamento form: ' + JSON.stringify(form));
            return orcamentoService.PostOrcamento(form)
                .then(
                    ({ data }) => {
                        // console.log('PostOrcamento data: ' + JSON.stringify(data));
                        store.commit('PostOrcamento', data)
                        return Promise.resolve(data)
                    },
                    error =>
                    Promise.reject(error)
                )
        }
    },
    mutations: {
        /* storeOrcamentos */
        GetOrcamento: (state, orcamentos) => {
            state.orcamentos = orcamentos;
        },
        PostOrcamento: (state, orcamento) => {
            state.orcamentos.push(orcamento);
            state.novoOrcamento = orcamento;
        }
    }
}