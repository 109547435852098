<template>
  <a-row type="flex" justify="space-around" align="middle">
    <a-card
      :bodyStyle="{ background: '#aed09f' }"
      :headStyle="{ background: '#313131', color: 'white' }"
      style="width: 95%; margin-top: 3%"
    >
      
        <template slot="title">
          {{ servicosPorAmbiente[current].ambienteNome }} -
          {{ servicosPorAmbiente[current].ambienteQtd }}
          <a-col style="margin-top: 20px; font-size: 1em" :span="24">
            Valor aproximado:
          </a-col>
          <a-col style="font-size: 1em" :span="24">
            R$ {{ servicosPorAmbiente[current].AmbienteVlrMinTotal | real }} -
            R$
            {{ servicosPorAmbiente[current].AmbienteVlrMaxTotal | real }}
          </a-col>
        </template>


      <a-list
        style="margin-top: 10px"
        :grid="{ sm: 1, md: 2, lg: 2, xl: 3, gutter: 24 }"
        :data-source="servicosPorAmbiente[current].NovosServicos"
      >
        <a-list-item
          slot="renderItem"
          slot-scope="item"
        >
          <a-row align="middle" justify="center">
            <a-card
              :class="'cardSize'"
              :headStyle="{ color: '#1f8800' }"
              :title="item.servicoTitulo"
            >
              <a-switch
                slot="extra"
                :checked="item.ServicoSelecionado"
                @change="checkService(item)"
              />

              <a-row align="middle" justify="center">
                <a-col :span="24">
                  {{ item.servicoDescricao }}
                </a-col>
                <a-col style="margin-top: 20px; font-size: 1.1em" :span="24">
                  Valor aproximado:
                </a-col>
                <a-col style="font-size: 1.1em" :span="24">
                  R$ {{ item.servicoValorMinimo | real }} - R$
                  {{ item.servicoValorMaximo | real }}
                </a-col>
              </a-row>
            </a-card>
          </a-row>
        </a-list-item>
      </a-list>
    </a-card>
    <a-row>
      <a-col :span="24">
        <h2 class="step-info">
          Ambiente atual: {{ servicosPorAmbiente[current].ambienteNome }} - {{ servicosPorAmbiente[current].ambienteQtd }}
          <a-col style="margin-top: 20px; font-size: 1em" :span="24">
          Valor aproximado:
        </a-col>
        <a-col style="font-size: 1em" :span="24">
          R$ {{ servicosPorAmbiente[current].AmbienteVlrMinTotal | real }} - R$
          {{ servicosPorAmbiente[current].AmbienteVlrMaxTotal | real }}
        </a-col>
        </h2>
      </a-col>

      <a-col :span="24">
        <div class="steps-action">
          <a-button
            :disabled="this.current == 0"
            style="margin-right: 8px; margin-top: 20px; margin-bottom: 20px"
            @click="prev"
          >
            Ambiente Anterior
          </a-button>

          <a-button
            :disabled="this.current == this.servicosPorAmbiente.length - 1"
            style="margin-top: 20px; margin-bottom: 20px"
            type="primary"
            @click="next"
          >
            Próximo Ambiente
          </a-button>
        </div>
      </a-col>
    </a-row>
  </a-row>
</template>

<script>
export default {
  name: "ServicesComponent",
  props: ["servicosPorAmbiente"],
  data() {
    return {
      current: 0,
    };
  },
  methods: {
    next() {
      if (this.current < this.servicosPorAmbiente.length - 1) {
        window.scrollTo(0, 0);
        this.current++;
      }
    },
    prev() {
      if (this.current > 0) {
        window.scrollTo(0, 0);
        this.current--;
      }
    },

    getWanted(changeService) {
      this.servicosPorAmbiente.forEach((ambient) => {
        if (ambient.AmbienteKey === changeService.AmbienteKey) {
          ambient.NovosServicos.forEach((service) => {
            if (
              service.vwAmbienteServicoId === changeService.vwAmbienteServicoId
            ) {
              if (service.ServicoSelecionado) {
                ambient.AmbienteVlrMinTotal += service.servicoValorMinimo;
                ambient.AmbienteVlrMaxTotal += service.servicoValorMaximo;
              }
              if (
                !service.ServicoSelecionado &&
                ambient.AmbienteVlrMinTotal > 0 &&
                ambient.AmbienteVlrMaxTotal > 0
              ) {
                ambient.AmbienteVlrMinTotal -= service.servicoValorMinimo;
                ambient.AmbienteVlrMaxTotal -= service.servicoValorMaximo;

                //Será zerado caso for ficar negativo
                if (ambient.AmbienteVlrMinTotal < 0) {
                  ambient.AmbienteVlrMinTotal = 0;
                }
                if (ambient.AmbienteVlrMaxTotal < 0) {
                  ambient.AmbienteVlrMaxTotal = 0;
                }
              }
            }
          });
        }
      });
    },
    checkService(service) {
      service.ServicoSelecionado = !service.ServicoSelecionado;
      this.getWanted(service);
    },
  },
  mounted() {},
};
</script>

<style scoped>
@media (min-width: 992px) {
  .cardSize {
    width: 95% !important;
    height: 250px !important;
    /* background-color: #33b86f; */
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .cardSize {
    width: 95% !important;
    height: 350px !important;
    /* background-color: #393f37af; */
  }
}
@media (max-width: 767px) and (min-width: 576px) {
  .cardSize {
    width: 95% !important;
    height: 370px !important;
    /* background-color: #1b4b21af; */
  }
}
@media (max-width: 575px) {
  .cardSize {
    width: 95% !important;
    height: 260px !important;
    /* background-color: #201170; */
  }
}

.step-info {
  color: #1f8800;
  font-size: 1.2em;
  margin-top: 20px;
  /* background-color: #4eb833; */
}
</style>